<template>
  <div class="ae">
    <div class="ae-title">
      当前报告
    </div>
    <div class="ae-item" v-if="aData.SerialNo">
      <div class="row row-1">
        <div class="name">{{aData.PersonName}}</div>
        <div class="date">{{aData.StartTime}}</div>
      </div>
      <div class="row">
        <i class="icon icon-bao"></i>
        体检号：{{aData.SerialNo}}
      </div>
      <div class="row">
        <i class="icon icon-yi"></i>
        济宁华医健康体检中心
      </div>
      <div class="info">
        <div class="link">
          <a href="tel:2310669">获取专家电话</a>
          <i class="icon icon-arrow"></i>
        </div>
        <div class="link" @click="handleInfo(aData.SerialNo)">
          AI资深解读
          <i class="icon icon-arrow"></i>
        </div>
        <div class="link">
          <a :href="aData.pdfUrl" download>电子报告下载</a>
          <i class="icon icon-arrow"></i>
        </div>
      </div>
    </div>
    <div v-if="bList.length">
      <div class="ae-title">
        历史报告
      </div>
      <div class="ae-item" v-for="(item, index) in bList" :key="index">
        <div class="row row-1">
          <div class="name">{{item.PersonName}}</div>
          <div class="date">{{item.StartTime}}</div>
        </div>
        <div class="row">
          <i class="icon icon-bao"></i>
          体检号：{{item.SerialNo}}
        </div>
        <div class="row">
          <i class="icon icon-yi"></i>
          济宁华医健康体检中心
        </div>
        <div class="info">
          <div class="link">
            <a href="tel:2310669">获取专家电话</a>
            <i class="icon icon-arrow"></i>
          </div>
          <div class="link" @click="handleInfo(item.SerialNo)">
            AI资深解读
            <i class="icon icon-arrow"></i>
          </div>
          <div class="link">
            <a :href="item.pdfUrl" download>电子报告下载</a>
            <i class="icon icon-arrow"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from '../api'
export default{
  data () {
    return {
      aData: {},
      bList: []
    }
  },
  methods: {
    getInfo() {
      api.get('report/result/list').then(res => {
        if (res.code === 200) {
          this.aData = res.data.now_list[0]
          if (res.data.old_list) {
            this.bList = res.data.old_list
          }
        }
      })
    },
    handleInfo (no) {
      this.$router.push({
        path: '/main',
        query: { no }
      })
    }
  },
  created() {
    this.getInfo()
  }
}
</script>
<style lang='less' scoped>
  .ae {
    padding: 24px;
    background: #FCFCFC;
    height: 100vh;
    .ae-title {
      font-size: 18px;
      height: 46px;
      padding-left: 6px;
      line-height: 46px;
      border-left: 8px solid #00B068;
      color: #2D2E30;
    }
    .ae-item {
      padding-top: 24px;
      margin: 26px 0;
      background: #fff;
      border-radius: 8px;
      box-shadow: 0px 0px 250px 0px rgba(0,0,0,0.1);
      .row {
        padding: 0 24px;
        color: #999999;
        font-size: 14px;
        height: 46px;
        line-height: 46px;
      }
      .row-1 {
        display: flex;
        justify-content: space-between;
        .name {
          color: #333333;
          font-size: 16px;
        }
        .date {
          
        }
      }
    }
    .info {
      display: flex;
      margin-top: 16px;
      padding: 0 32px;
      height: 64px;
      align-items: center;
      justify-content: space-between;
      background-color: #00B068;
      border-bottom-right-radius: 16px;
      border-bottom-left-radius: 16px;
      color: #FFFFFF;
      font-size: 14px;
      .link {
        color: #fff;
      }
      a {
        text-decoration: none;
        color: #fff;
      }
    }
  }
</style>
